import axios from "axios";
import { TOKEN } from "@/utils/constants";

const token = localStorage.getItem(TOKEN);
const header = { Authorization: `Bearer ${token}` };

const baseURL = process.env.VUE_APP_BASE_URL;

export function inventory() {
  return axios.get(`${baseURL}/dashboard/inventory_item`, { headers: header });
}

export function executive(payload) {
  return axios.post(
    `${baseURL}/dashboard/exective?start_date=${payload.start_date}&end_date=${payload.end_date}&branch_id=${payload.branch}`,
    {},
    {
      headers: header,
    }
  );
}

export function removalLogs(month, branch_id) {
  return axios.get(
    `${baseURL}/dashboard/removal-schedule?month=${month}&branch_id=${branch_id}`,
    { headers: header }
  );
}

export function dashboard() {
  return axios.get(`${baseURL}/dashboard/finance-chart?count=10`, {
    headers: header,
  });
}

export function accounting(date) {
  return axios.get(`${baseURL}/dashboard/account_report?date=${date}`, {
    headers: header,
  });
}
