<template>
  <div class="py-8 px-0">
    <h1 class="text-2xl font-bold">Dashboard</h1>
    <div class="flex flex-wrap gap-5 w-full my-10">
      <div
        class="bg-white rounded-xl p-5 flex items-center gap-5 w-1/4"
        v-for="items in dashboard_item"
        :key="items.id"
      >
        <div class="bg-blue-300 bg-opacity-30 p-3 rounded-full">
          <bed-icon class="w-10 h-auto" />
        </div>
        <div class="">
          <h3 class="text-2xl font-bold text-gray-700">{{ items.count }}</h3>
          <p>{{ items.inventory_item_name }}</p>
        </div>
      </div>
    </div>
    <div v-if="items.length !== 0">
      <inventory-table
        :allItems="items.slice(0, 5)"
        :perPage="perPage"
        :total="total"
        :currentPage="currentPage"
        type="some"
        :action="false"
      />
      <div class="flex justify-end">
        <router-link
          to="/app/inventory"
          class="bg-primary py-3 px-10 text-white rounded-md shadow-md"
        >
          View More
        </router-link>
      </div>
    </div>
    <div class="h-full w-full flex items-center justify-center" v-else>
      <Spinner />
    </div>
  </div>
</template>

<script>
import { fetchInventories } from "@/services/inventory";
import { inventory } from "@/services/dashboard";

export default {
  components: {
    BedIcon: () => import("@/assets/icons/BedIcon.vue"),
    // SeatIcon: () => import("@/assets/icons/SeatIcon.vue"),
    InventoryTable: () => import("@/components/Inventory/InventoryTable.vue"),
    Spinner: () => import("@/components/Base/Spinner.vue"),
  },
  data() {
    return {
      items: [],
      dashboard_item: [],
      perPage: 0,
      currentPage: 0,
      total: 0,
    };
  },
  methods: {
    async fetchDashboard() {
      try {
        const response = await inventory();
        this.dashboard_item = response.data.inventory_item;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchInventory(page = 1) {
      try {
        const inventories = await fetchInventories(page);
        this.items = inventories.data.inventory.data;
        this.currentPage = inventories.data.inventory.current_page;
        this.perPage = inventories.data.inventory.per_page;
        this.total = inventories.data.inventory.total;
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchDashboard();
    this.fetchInventory();
  },
};
</script>
